import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA PROVINSI------
    getProvinsiAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/provinsi/all", { params: credentials })
    },
    getProvinsiSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/provinsi/single", { params: credentials })
    },
    verifProvinsi(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/master/provinsi", credentials)
    },

    // -----API DATA Kabupaten------
    getAllKabupaten(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/kabupaten/all", { params: credentials })
    },
    getKabupatenSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/kabupaten/single", { params: credentials })
    },
    verifKabupaten(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/master/kabupaten", credentials)
    },

    // -----API DATA Kecamatan------
    getAllKecamatan(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/kecamatan/all", { params: credentials })
    },
    getKecamatanSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/kecamatan/single", { params: credentials })
    },
    verifKecamatan(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/master/kecamatan", credentials)
    },

    // -----API DATA Desa------
    getAllDesa(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/desa/all", { params: credentials })
    },
    getDesaSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/desa/single", { params: credentials })
    },
    verifDesa(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/master/desa", credentials)
    },

}