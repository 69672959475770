<template>
  <div id="verifikasi-master-data-wilayah-list" data-app>
   
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Verifikasi Wilayah
          </h3>
        </div>
      </div>

      <!--begin::Table-->
        <div class="card-body pt-0 pb-0">

          <b-row class="mt-3 mb-2">
            <b-col cols="12" sm="7">
              <b-button 
              class="mr-1"
              size="sm"
              v-b-tooltip.hover title="Filter Wilayah"
              variant="primary"
              @click="popupFilter()">
                <span class="svg-icon svg-icon-lg m-0">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/General/Search.svg"/>
                  <!--end::Svg Icon-->
                </span>
                <span class="d-sm-inline d-none">
                  Filter
                </span>
              </b-button>
            </b-col>       
            <b-col cols="12" sm="5">
              <div class="input-icon h-40px max-w-300px float-sm-right">
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid h-40px"
                    placeholder="Search..."
                    v-model="search"
                  />
                  <span>
                    <span class="svg-icon svg-icon-lg">
                      <!--begin::Svg Icon-->
                      <inline-svg src="media/svg/icons/General/Search.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
            </b-col>
          </b-row>

          <v-data-table
            :headers="fields"
            :items="items"
            :page.sync="currentPage"
            :items-per-page="perPage"
            hide-default-footer
            class="elevation-1"
            disable-sort
            :loading="isBusy"
            loading-text="Loading... Please wait"
          >

            <template v-slot:[`item.action`]="{ item }">
              <div class="float-right">
                
                <b-button 
                v-b-tooltip.hover title="Verifikasi Wilayah"
                class="btn-icon mr-1"
                variant="clean"
                @click="verifikasiData({ wilayah: item.value, status: 'verif' })">
                  <span class="svg-icon svg-icon-primary svg-icon-lg m-0">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Shield-check.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </b-button>
                
                <b-button 
                v-b-tooltip.hover title="Tolak Wilayah"
                class="btn-icon"
                variant="clean"
                @click="verifikasiData({ wilayah: item.value, status: 'tolak' })">
                  <span class="svg-icon svg-icon-danger svg-icon-lg m-0">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Shield-disabled.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </b-button>
              </div>
            </template>
          </v-data-table>
        </div>
      <!--end::Table-->  

      <div class="card-footer">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="changePage" align="center"></b-pagination>
      </div>

    </div>

    <v-dialog
    v-model="dialogFilter"
    persistent
    max-width="500">
      <div class="card card-custom">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Filter Data
            </h3>
          </div>
        </div>

        <!--begin::Form-->
          <div class="card-body">
            <ValidationObserver ref="form">
            <b-form @submit="onSubmitFilter" @reset="onResetFilter" v-if="show">

              <validationProvider name="Level" rules="required" v-slot="{ errors, valid }">
                <b-form-group
                  id="input-group-1" 
                  label="Level:" 
                  label-for="input-1"
                  :invalid-feedback="errors[0]"
                  :state="valid">
                  <b-form-select2
                    id="input-1"
                    v-model="form.level"
                    :options="opsLevel"
                    required
                    :state="valid"
                  ></b-form-select2>
                </b-form-group>
              </validationProvider>

              <validationProvider v-if="(['provinsi', 'kabupaten', 'kecamatan']).includes(levelForm.id)" name="Provinsi" rules="required" v-slot="{ errors, valid }">
                <b-form-group
                  id="input-group-2" 
                  label="Provinsi:" 
                  label-for="input-2"
                  :invalid-feedback="errors[0]"
                  :state="valid">
                  <b-form-select2
                    id="input-2"
                    v-model="form.provinsi"
                    :options="opsProvinsi"
                    required
                    :state="valid"
                  ></b-form-select2>
                </b-form-group>
              </validationProvider>

              <validationProvider v-if="(['kabupaten', 'kecamatan']).includes(levelForm.id)" name="Kabupaten" rules="required" v-slot="{ errors, valid }">
                <b-form-group
                  id="input-group-2" 
                  label="Kabupaten:" 
                  label-for="input-2"
                  :invalid-feedback="errors[0]"
                  :state="valid">
                  <b-form-select2
                    id="input-2"
                    v-model="form.kabupaten"
                    :options="opsKabupaten"
                    required
                    :state="valid"
                  ></b-form-select2>
                </b-form-group>
              </validationProvider>

              <validationProvider v-if="(['kecamatan']).includes(levelForm.id)" name="Kecamatan" rules="required" v-slot="{ errors, valid }">
                <b-form-group
                  id="input-group-3" 
                  label="Kecamatan:" 
                  label-for="input-3"
                  :invalid-feedback="errors[0]"
                  :state="valid">
                  <b-form-select2
                    id="input-3"
                    v-model="form.kecamatan"
                    :options="opsKecamatan"
                    required
                    :state="valid"
                  ></b-form-select2>
                </b-form-group>
              </validationProvider>

            </b-form>
            </ValidationObserver>
          </div>

          <div class="card-footer">
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-9">
                <button 
                  @click="onSubmitFilter" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold"
                >
                  <i class="la la-eye"></i>
                  Filter
                </button>
                &nbsp;
                <button
                  @click="dialogFilter = false"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Close
                </button>
              </div>
            </div>
          </div>
        <!--end::Form-->
      </div>
    </v-dialog>

    <v-dialog
    v-model="dialogVerif"
    max-width="300">
      <v-card>
        <v-card-title class="headline">Action data</v-card-title>

        <v-card-text>
          Apakah anda yakin ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="dialogVerif = false">
            BATAL
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="onVerif">
            LANJUTKAN
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogProcess" persistent scrollable max-width="500px">
      <component v-if="curComponent" :idWL="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPDATE_COUNT } from "@/core/services/store/verification.module";
import WilayahService from "@/core/services/api/master/wilayah.service";
import VerifikasiService from "@/core/services/api/verifikasi/master/wilayah.service";
import ErrorService from "@/core/services/error.service";

import FormEditProvinsi from "@/view/pages/master-data/wilayah/provinsi/Edit.vue";
import FormEditKabupaten from "@/view/pages/master-data/wilayah/kabupaten/Edit.vue";
import FormEditKecamatan from "@/view/pages/master-data/wilayah/kecamatan/Edit.vue";
import FormEditDesa from "@/view/pages/master-data/wilayah/desa/Edit.vue";

export default {
  name: "verifikasi-master-data-wilayah-list",
  components: {
    FormEditProvinsi,
    FormEditKabupaten,
    FormEditKecamatan,
    FormEditDesa
  },
  data() {
    return {
      tabIndex: 0,
      show: true,
      levele: 1,
      idWil: null,
      search: null,
      searchTimeout: null,
      isBusy: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      fields: [{ value: "text", text: "Provinsi" }, { value: "action", text: "Act", class: "text-right"} ],
      items: [],
      dialogVerif: false,
      dialogProcess: false,
      dialogFilter: false,
      sdataForm: null,
      curComponent: null,
      dataVerif: null,
      form: {
        level: 1,
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        search: null,
      },
      opsLevel: [{
        value: 1,
        text: "Provinsi",
        url: "provinsi",
        id: null,
        link: {
          get: "getProvinsiAll",
          Verif: "VerifProvinsi"
        } 
      }, {
        value: 2,
        text: "Kabupaten",
        url: "kabupaten",
        id: "provinsi",
        link: {
          get: "getAllKabupaten",
          Verif: "VerifKabupaten"
        } 
      }, {
        value: 3,
        text: "Kecamatan",
        url: "kecamatan",
        id: "kabupaten",
        link: {
          get: "getAllKecamatan",
          Verif: "VerifKecamatan"
        } 
      }, {
        value: 4,
        text: "Desa",
        url: "desa",
        id: "kecamatan",
        link: {
          get: "getAllDesa",
          Verif: "VerifDesa"
        } 
      }],
      opsProvinsi: [{
        value: null,
        text: "Silahkan pilih Provinsi"
      }],
      opsKabupaten: [{
        value: null,
        text: "Silahkan pilih Kabupaten"
      }],
      opsKecamatan: [{
        value: null,
        text: "Silahkan pilih Kecamatan"
      }],
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentCount"
    ]),
    level () {
      return this.opsLevel.find(item => item.value == this.levele)
    },
    levelForm () {
      return this.opsLevel.find(item => item.value == this.form.level)
    }
  },
  watch: {
    'search' (val, old) {
      if (val != old) {
        clearTimeout(this.searchTimeout)

        this.searchTimeout = setTimeout(() => {
          this.getData(1, this.perPage, val)
        }, 750)
      }
    },
    
    'form.level' (val, old) {
      if (val != old) {
        if (val == null) {
          this.opsKecamatan= [{
            value: null,
            text: "Silahkan pilih Kecamatan"
          }]
          this.opsKabupaten= [{
            value: null,
            text: "Silahkan pilih Kabupaten"
          }]
          this.opsProvinsi= [{
            value: null,
            text: "Silahkan pilih Provinsi"
          }]
          return
        }

        if ((['kabupaten', 'kecamatan']).includes(this.levelForm.id)) {
          this.getKabupaten({provinsi: this.form.provinsi, aks:1})
        }
        if ((['kecamatan']).includes(this.levelForm.id)) {
          this.getKecamatan({kabupaten:this.form.kabupaten, aks:1})
        }
      }
    },
    'form.provinsi' (val, old) {
      if (val != old) {
        if (val == null) {
          this.opsKabupaten= [{
            value: null,
            text: "Silahkan pilih Kabupaten"
          }]
          return
        }

        if (this.form.kabupaten) {
          this.form.kabupaten = null
          this.form.kecamatan = null
        }
        if ((['kabupaten', 'kecamatan']).includes(this.levelForm.id)) {
          this.getKabupaten({ provinsi: val, aks: 1 })
        }
      }
    },
    'form.kabupaten' (val, old) {
      if (val != old) {
        if (val == null) {
          this.opsKecamatan= [{
            value: null,
            text: "Silahkan pilih Kecamatan"
          }]
          return
        }
        if (this.form.kecamatan) {
          this.form.kecamatan = null
        }
        if ((['kecamatan']).includes(this.levelForm.id)) {
          this.getKecamatan({ kabupaten: val, aks: 1 })
        }
      }
    },
  },
  methods: {
    changePage(val){
      if(this.currentPage == val) return
      this.isBusy = true
      this.items = []
      this.getData(val)
    },
    resetTable() {
      let count = this.currentCount
      count.master.wilayah = 0
      this.$store.dispatch(UPDATE_COUNT, count);
      this.items = []
      this.totalRows = 0
      this.currentPage = 1
    },
    getProvinsi() {
      WilayahService.getProvinsi({ aks: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsProvinsi = [{
                  value: null,
                  text: "Silahkan pilih Provinsi"
                }]
                return;
            }

            this.opsProvinsi = res.data.data
            this.opsProvinsi.push({
              value: null,
              text: "Silahkan pilih Provinsi"
            })
        })
        .catch((err)=> {
          this.opsProvinsi = [{
            value: null,
            text: "Tidak ada Provinsi"
          }]
          ErrorService.status(err)
        })
    },
    getKabupaten(param) {
      const fault = [{
        value: null,
        text: "Silahkan pilih Kabupaten"
      }]

      if (!param.provinsi) {
        this.opsKabupaten = fault 
        return;
      }
      
      WilayahService.getKabupaten(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKabupaten = fault 
                return;
            }

            this.opsKabupaten = [ ...res.data.data, ...fault ]
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsKabupaten = fault 
        })
    },
    getKecamatan(param) {
      const fault = [{
        value: null,
        text: "Silahkan pilih Kecamatan"
      }]

      if (!param.kabupaten) {
        this.opsKecamatan = fault 
        return;
      }

      WilayahService.getKecamatan(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKecamatan = fault 
                return;
            }

            this.opsKecamatan = [ ...res.data.data, ...fault ]
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsKecamatan = fault 
        })
    },
    getData (page = 1, perPage = this.perPage, search = this.search) {
      VerifikasiService[this.level.link.get]({
        page, 
        per_page: perPage, 
        search, 
        aks: 0,
        id: (this.level.id ? this.idWil : null)
      })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.resetTable()
                this.isBusy = false
                return;
            }
            this.fields = [{ value: "text", text: this.level.text }, { value: "action", text: "Act", class: "text-right"} ]
            this.items = res.data.data.data
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.total
            let count = this.currentCount
            count.master.wilayah = res.data.data.total
            this.$store.dispatch(UPDATE_COUNT, count);
            this.isBusy = false
        })
        .catch((err) => {
          this.resetTable()
          this.isBusy = false
          return ErrorService.status(err)
        })
    },
    popupFilter() {
      this.dialogFilter = true
    },
    VerifPopupData(val) {
      this.dataVerif = val
      this.dialogVerif = true
    },
    processWilayah(id, param = false, data = null) {
      this.curComponent = id
      let setData = null
      if (param) {
        setData = this.form
      }
      if (!param && data) {
        setData = data
      }
      this.sdataForm = setData
      this.dialogProcess = true
    },
    closeDialogForm(res) {
      if (res.status) {
        if (res.refresh) {        
          this.getData(this.currentPage)          
        }
        this.curComponent = null
        this.sdataForm = null
        this.dialogProcess = false
        this.dialogAdd = false
      }
    },
    onVerif () {
      if (! this.dataVerif) {
        return
      }
      VerifikasiService[this.level.link.Verif]({ ...this.dataVerif, aks: 0 })
        .then((res) => {
            if (res.data.status) { 
              this.getData(this.currentPage)              
            }
            ErrorService.message(res.data)
            this.dialogVerif = false
            this.dataVerif = null
        })
        .catch((err) => {
          this.dialogVerif = false
          this.dataVerif = null
          return ErrorService.status(err)
        })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmitFilter(evt) {
      evt.preventDefault()
      this.$refs.form.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(async () => {
            const getLevel = await this.opsLevel.find(item => item.value == this.form.level)
            this.levele = this.form.level
            this.idWil = (getLevel.id ? this.form[getLevel.id] : null)
            this.search = null
            this.dialogFilter = false
            this.$nextTick(() => {
              this.getData(1)
            })

            this.removeSpinner(submitButton)
        }, 1000);
      })
    },
    onResetFilter(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form.level = 1
      this.form.provinsi = null
      this.form.kabupaten = null
      this.form.kecamatan = null
      this.search = ""

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verif Master Data Wilayah" }]);
    if (!this.currentUser.menu.verifikasi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    if (this.$route.query.level && this.$route.query.id) {
      this.levele = this.$route.query.level
      this.idWil = this.$route.query.id
      this.form.level = this.$route.query.level
    }
    this.getProvinsi()
    this.getData()
  },
};
</script>